import { observable, computed, action, toJS } from 'mobx';
import findLastIndex from 'lodash/findLastIndex';
import groupBy from 'lodash/groupBy';
import get from 'lodash/get';
import { CUTLERY_PRICE } from '../services/constants';
import uniqBy from 'lodash/uniqBy';
import countBy from 'lodash/countBy';
import CartServices from '../store/services/cartServices';
import Notifiable from './services/notifiable';
import { isToday, parseToXDate } from '../services/dateTime';
import XDate from 'xdate';
import TerminalMenuStore from './terminalMenuStore';

import { services } from '../services/index';
import { GetUrl } from '../services/url';
import { currency } from '../services';
import intl from 'react-intl-universal';

import Bacon from 'baconjs';
import template from 'lodash/template';
import { last } from 'lodash';

class TerminalCartStore extends Notifiable {
    @observable itemsAdded = [];
    @observable hasFetchedLocalStorage = false;
    @observable message = '';
    @observable hasValidated = false;
    @observable preliminaryOrderSummary = null;
    @observable.ref user = null;
    @observable redirectToCheckout = false;
    @observable dishSoldOut = false;
    @observable orderSummary = [];
    @observable minOrder = null;
    @observable deliveryCharge = 0;
    @observable serviceChargeMin = null;
    @observable isAddressLoading = true;
    @observable isTimeframeLoading = true;
    @observable couponMenuItemId = null;
    @observable totalPrice = null;
    @observable couponErrMessage = null;
    @observable comboCartObject = null;
    @observable randomItemId = null;
    @observable cartItems = [];
    @observable isCutleryProvided = false;
    @observable comboCheck = false;
    @observable checkValidation = false;
    @observable comboCartTotalPrice = null;
    @observable comboToggle = false;

    onItemAdded$ = new Bacon.Bus();

    @computed get cartServices() {
        return new CartServices(this.itemsAdded);
    }

    @computed get cartTotal() {
        return this.itemsAdded.map((item) => item.price).reduce((a, b) => a + b, 0);
    }

    @computed get balanceNeeded() {
        return this.serviceChargeMin - this.cartTotal;
    }

    @computed get finalCartTotal() {
        if (this.balanceNeeded > 0) {
            return this.cartTotal + this.balanceNeeded;
        } else {
            return this.cartTotal;
        }
    }

    constructor() {
        super();
        this.setUpNotification();
    }

    getUserWithMemoizer() {
        const token = services.api.AccessTokenStorage.getToken();

        return new Promise((resolve, reject) => {
            if (token) {
                return services.api.GetUserWithMemoizer.run().then(resolve).catch(reject);
            }
            return reject();
        });
    }

    hasItems() {
        return this.itemsAdded.length !== 0;
    }

    @computed get numOfItems() {
        return this.itemsAdded.length.toString().substring(0, 2);
    }

    @computed get totalPriceItems() {
        return this.totalPrice;
    }

    @action setFreeMenuId = (id) => {
        this.couponMenuItemId = id;
    };

    isInCart(item) {
        return findLastIndex(this.itemsAdded, (itemInCart) => itemInCart.today_menu_id === item.today_menu_id) >= 0;
    }

    @action loadItems(items) {
        this.itemsAdded = items || [];
        this.hasFetchedLocalStorage = true;
        this.cartIsValid();

        //only now load the timeslots
        //in cart validation hasValidated set to true after finishing
    }

    getRandomId() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
            (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
        );
    }

    @action getTotalPrice(item) {
        const isFullCartPrice = !item;
        const items = item ? item : this.getSnapshot();
        let totalComboList = TerminalMenuStore.totalComboList;
        let totalMenuList = TerminalMenuStore.totalMenuList;



        const requestBody = {
            currency: 'MYR',
            menu_item: this.cartServices.createPosOrders({ items }),
            pick_up_type: TerminalMenuStore.orderType,
            reward_code: TerminalMenuStore.couponDisable ? TerminalMenuStore.couponTxt : '',
            provide_cutlery: this.isCutleryProvided,
        };

        services.api
            .PosPricesUpdate({
                requestBody,
                bearerToken: TerminalMenuStore.bearerToken,
            })
            .then((response) => {
                this.couponErrMessage = response.reward_error_message;
                if (isFullCartPrice) {
                    this.totalPrice = response.total;
                } else {
                    this.comboCartTotalPrice = response.total;
                }
                
                if (item && item[0].type == "combo") {
                    totalComboList[item[0].id]['totalPrice'] = response.total;
                    TerminalMenuStore.totalComboList = totalComboList;
                } else if (item && item[0].type == 'meal') {
                    totalMenuList[item[0].id]['totalPrice'] = response.total;
                    TerminalMenuStore.totalMenuList = totalMenuList;
                }


            });
    }

    @action updateInCartOrder(item) {
        const items = item ? item : this.getSnapshot();

        const requestBody = {
            currency: 'MYR',
            menu_item: this.cartServices.createPosOrders({ items }),
            pick_up_type: TerminalMenuStore.orderType,
        };

        services.api
            .UpdateInCartOrder({
                requestBody,
                bearerToken: TerminalMenuStore.bearerToken,
            });
    }

    orderHasMainMeal() {
        let hasMainMeal = false;
        const items = this.cartServices.getItems();
        if (!items) {
            return hasMainMeal;
        }

        items.forEach((item, index) => {
            if (
                item.item_type == 'Item' ||
                item.item_type == 'Combo' ||
                (item.type == 'combo' && item.today_menu_id.number_of_mains > 0)
            ) {
                hasMainMeal = true;
                return false;
            }
        });
        return hasMainMeal;
    }

    toggleCutlerySwitch() {
        this.isCutleryProvided = !this.isCutleryProvided;
    }

    getCutleryPrice() {
        return CUTLERY_PRICE;
    }

    getPriceOfItemInCart(item) {
        const items = this.cartServices.getItems();
        const totalItemPrice = items.reduce((total, itemInCart) => {
            let price = 0;
            if (itemInCart.today_menu_id === item.today_menu_id) {
                if (itemInCart.isValueMealOption) {
                    price = itemInCart.value_meal_price;
                } else {
                    price = itemInCart.special_offer_price ? itemInCart.special_offer_price : itemInCart.price;
                }
            }

            return total + price;
        }, 0);
        return parseFloat(totalItemPrice.toFixed(2));
    }

    @action addMealCart({ id, add } = {}) {
        let items = TerminalMenuStore.totalMenuList;

        let comboObjectValue = {};
        let valueObject = [];

        Object.keys(items[id]).forEach((type, index) => {
            let existValue;

            if (this.itemsAdded.length > 0) {
                existValue = this.itemsAdded?.filter((o) => {
                    return o.id == id

                })
            }

            comboObjectValue['today_menu_id'] = { id: id, ...TerminalMenuStore.cartItem };

            comboObjectValue.quantity = existValue && existValue.length > 0 ? existValue[0].quantity : 1;
            if (items[id][type].length > 0) {
                items[id][type].map((o) => {
                    valueObject.push(o)

                })

            }


        })

        comboObjectValue['type'] = 'meal';
        comboObjectValue['id'] = id;
        comboObjectValue['value_meal_menu_item'] = valueObject;

        if (valueObject.length > 0) {
            this.toggleCombo = true
        } else {
            this.toggleCombo = false

        }

        this.addToCart(comboObjectValue, { edit: add ? false : true })
        this.getTotalPrice(add ? [comboObjectValue] : '');
    }


    @action addComboCart({ validate, add } = {}) {
        const comboitem = TerminalMenuStore.comboObject;
        let items = TerminalMenuStore.totalComboList;
        let comboObjectValue = {};

        let valueObject = [];


        let checkValidation = true;
        this.comboCheck = false;
        Object.keys(items).forEach((value, index) => {
            if (value == comboitem['id']) {
                comboObjectValue['today_menu_id'] = comboitem;
                let existValue;
                // 
                if (this.itemsAdded.length > 0) {
                    existValue = this.itemsAdded?.filter((o) => {
                        return o.id == comboitem['id']

                    })
                } 

                comboObjectValue.quantity = existValue && existValue.length > 0 ? existValue[0].quantity : 1;



                Object.keys(items[value]).forEach(function (type, index) {
                    const valueCounts = countBy(items[value][type], 'menu_item_id');

                    if (type != 'totalPrice' && type != 'validation') {
                        items[value][type].filter((o, key) => {
                            if (!o.menu_item_id) {
                                checkValidation = false;
                                if (validate) {
                                    items[value][type][key]['error'] = true;
                                }
                            }
                        });
                    }
                    let comboUniq = uniqBy(items[value][type], 'menu_item_id');
                    Object.keys(valueCounts).forEach(function (menuid, key) {
                        if (menuid != 'undefined') {
                            comboUniq.filter((o, key) => {
                                if (o.menu_item_id == menuid) {
                                    valueObject.push({
                                        value: o,
                                        quantity: valueCounts[menuid],
                                    });
                                }
                            });
                        }
                        // ...
                    });
                });
            }
        });
        comboObjectValue['type'] = 'combo';
        comboObjectValue['id'] = comboitem['id'];
        comboObjectValue['value_meal_menu_item'] = valueObject;




        if (checkValidation) {
            items[comboitem['id']].validation = true;
            TerminalMenuStore.totalComboList = items;
        }
            this.addToCart(comboObjectValue, { edit: add ? false : true });


        this.getTotalPrice([comboObjectValue]);

    }

    @action addToCart(item, { edit, add, isValueMealItem } = {}) {
        const currentCart = toJS(this.itemsAdded);
        const amountInCart = currentCart.filter(
            (eachAddedItem) => eachAddedItem.today_menu_id === item.today_menu_id
        ).length;
        let itemToAdd = item;
        let comboKey;


        const filterCombo = this.itemsAdded.filter((o, key) => {
            if (o.id == item.id) {
                comboKey = key;
                return true;
            }
        });


        if (itemToAdd.quantity_left < amountInCart + 1) {
            this.message = intl.get('cart.messages.mealNotAvailable') + itemToAdd.title_bold;
        } else if (item.type == 'combo' || item.type == "meal") {
            if ((filterCombo.length > 0 || edit) && !add) {

                this.itemsAdded[comboKey] = itemToAdd;
            } else if (filterCombo.length == 0) {
                this.itemsAdded = this.itemsAdded.concat(itemToAdd);
            } else if (add) {

                itemToAdd.quantity = itemToAdd.quantity += 1;
                this.itemsAdded[comboKey] = itemToAdd;

            }



            this.saveSnapshot(toJS(this.itemsAdded));

        } else {

            this.itemsAdded = this.itemsAdded.concat(itemToAdd);

            this.saveSnapshot(toJS(this.itemsAdded));

        }



    }

    @action removeItemFromCart(item) {
        const filterCombo = this.itemsAdded.filter((o, key) => {
            if (o.id == item.id) {
                return true;
            }
        });

        const lastIndex = findLastIndex(
            this.itemsAdded,
            (itemInCart) => itemInCart.today_menu_id === item.today_menu_id
        );


        if (item.type == 'combo' || item.type == "meal") {
            let quantity = filterCombo[0].quantity - 1;

            this.itemsAdded[lastIndex].quantity = quantity;
            if (quantity < 1) {
                this.itemsAdded.splice(lastIndex, 1);

            }
        } else {
            this.itemsAdded.splice(lastIndex, 1);

        }



        this.saveSnapshot(toJS(this.itemsAdded));
    }
    @action removeMeal({ date }) {
        const items = this.cartServices.getItems();
        items.forEach((item) => {
            this.itemsAdded.remove(item);
        });
        this.saveSnapshot(toJS(this.itemsAdded));
        this.message = intl.get('cart.messages.mealsRemoved');
    }

    @action cartIsValid() {
        var dishIds = [...new Set(toJS(this.itemsAdded).map((item) => item.today_menu_id))];

        for (let [index, id] of dishIds.entries()) {
            // setup
            let show_message = false;
            let currentCart = toJS(this.itemsAdded);
            let quantity_left;
            let removed_item_name = '';

            // get amount in cart of that dish
            let amountInCart = currentCart.filter((eachAddedItem) => eachAddedItem.today_menu_id === id).length;

            // get quantity available
            services.api
                .GetDish({ id })
                .then((response) => {
                    quantity_left = response.quantity_left;
                })
                .then(() => {
                    // set quantity_left property of items in localstorage cart to quantity available now
                    for (let item of this.itemsAdded) {
                        if (item.today_menu_id === id) {
                            item.quantity_left = quantity_left;
                        }
                        this.saveSnapshot(toJS(this.itemsAdded));
                    }

                    let currentCart = toJS(this.itemsAdded);
                    //temporary fix: remove items from cart which have menu-date in past
                    for (let current_item of currentCart) {
                        const menuDate = parseToXDate(current_item.menu_date);
                        if (!isToday(menuDate) && menuDate <= new XDate()) {
                            this.removeItemFromCart(current_item);
                            try {
                                removed_item_name = current_item.title_bold;
                            } catch (err) {}
                        }
                    }

                    // compare and enter removal loop until quantities available + in-cart match
                    while (amountInCart > quantity_left) {
                        //let currentCart = toJS(this.itemsAdded)
                        currentCart = toJS(this.itemsAdded);
                        const current_item = currentCart.filter((i) => i.today_menu_id === id)[0];
                        this.removeItemFromCart(current_item);
                        currentCart = toJS(this.itemsAdded);
                        amountInCart = currentCart.filter((eachAddedItem) => eachAddedItem.today_menu_id === id).length;
                        const menuDate = parseToXDate(current_item.menu_date);
                        if (isToday(menuDate) || menuDate > new XDate()) {
                            show_message = true;
                            try {
                                removed_item_name = current_item.title_bold;
                            } catch (err) {}
                        }
                    }

                    //send notification message to user
                    if (show_message) {
                        if (quantity_left === 0) {
                            this.dishSoldOut = true;
                            services.bannerEvents.showWarning(
                                template(intl.get('cart.messages.mealNotAvailableAnymore'))({ dish: removed_item_name })
                            );
                        } else {
                            quantity_left > 1
                                ? (this.message =
                                      intl.get('cart.messages.tooManyInCart') +
                                      quantity_left +
                                      ' ' +
                                      removed_item_name +
                                      intl.get('cart.messages.mealLeft'))
                                : (this.message =
                                      intl.get('cart.messages.oneTooManyInCart') +
                                      quantity_left +
                                      ' ' +
                                      removed_item_name +
                                      intl.get('cart.messages.mealLeft'));
                        }
                    }
                })
                .catch((ignore) => {})
                .then(() => {
                    this.hasValidated = index + 1 === dishIds.length ? true : false;
                });
        }
    }

    @action getMinimumOrder = (user) => {
        const city = services.getParam('city');

        if (this.minOrder === null) {
            services.api.GetMenuTimeSettings({ city }).then((response) => {
                this.minOrder = response.min_order_amount_per_delivery;
                this.serviceChargeMin = response.service_charge_applies_below;
                this.deliveryCharge = response.delivery_charge;
            });
        }
    };

    @action checkCartValid = () => {
        if (this.user) {
            if (this.preliminaryOrderSummary && this.preliminaryOrderSummary.sub_total < this.minOrder) {
                const minOrderCurrency = currency.localCurrency(this.minOrder);
                services.bannerEvents.showWarning(
                    template(intl.get('menu.warnings.minOrder'))({
                        minOrderCurrency,
                    })
                );
            } else {
                this.redirectToCheckout = true;
            }
        } else {
            if (this.cartTotal < this.minOrder) {
                const minOrderCurrency = currency.localCurrency(this.minOrder);
                services.bannerEvents.showWarning(
                    template(intl.get('menu.warnings.minOrder'))({
                        minOrderCurrency,
                    })
                );
            } else {
                this.redirectToCheckout = true;
            }
        }
    };

    @action handleCartClicked = () => {
        this.checkCartValid();
        if (this.redirectToCheckout) {
            window.location.href = `${GetUrl()}/cart`;
        }
    };

    convertItemsToOrders(preliminary) {
        const cartServices = new CartServices(this.items);
        const groupedByDate = groupBy(this.items, 'menu_date');
        const orders = [];

        for (const dateString in groupedByDate) {
            const itemsForDate = groupedByDate[dateString];
            const groupedByMealTime = groupBy(itemsForDate, 'menu_type');

            for (const mealTime in groupedByMealTime) {
                const items = groupedByMealTime[mealTime];
                const chosenTimeslotId = get(this.userChosenTimeslots, [dateString, mealTime]);
                const timeSlotString = preliminary ? undefined : this.getTimesSlotByPaymentMethod(chosenTimeslotId);
                const addressId = get(this.userChosenAddresses, [dateString, mealTime]);

                const order = cartServices.createOrders({
                    items,
                    mealTime,
                    dateString,
                    timeSlotString,
                    addressId,
                });

                orders.push(order);
            }
        }

        return orders;
    }

    getItems() {
        return this.itemsAdded || [];
    }

    @action saveSnapshot(items) {
        this.cartItems = items;
    }

    @action getSnapshot() {
        return this.cartItems;
    }

    @action filter(predicate) {
        try {
            const items = this.getSnapshot();
            const filteredItems = items.filter(predicate);

            this.saveSnapshot(filteredItems);
        } catch (ex) {} // eslint-disable-line no-empty
    }

    @action clear() {
        this.cartItems = [];
        this.isCutleryProvided = false;
    }
}

const store = new TerminalCartStore();
export default store;
